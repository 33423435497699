import React from 'react'

type Item = {
  letter: string
  state: boolean
}

type FormProps = {
  currentLevel: string
  roundCompletedCallback: () => void
}

type FortState = {
  grid: Item[][],
  guessed: boolean
  showTiger: boolean
}

const bitem = (l: string) => ({letter: l, state: false})

class Fort extends React.Component<FormProps, FortState> {
  constructor(props: FormProps) {
    super(props)

    this.state = {
      guessed: false,
      showTiger: false,
      grid: [
        [bitem('М'), bitem('Д'), bitem('Й'), bitem('Г'), bitem('Н'), bitem('Е')],
        [bitem('О'), bitem('Х'), bitem('Ж'), bitem('Л'), bitem('И'), bitem('Я')],
        [bitem('С'), bitem('Ф'), bitem('О'), bitem('Р'), bitem('Т'), bitem('П')],
        [bitem('К'), bitem('Б'), bitem('О'), bitem('Я'), bitem('Р'), bitem('Д')],
        [bitem('М'), bitem('Е'), bitem('Ы'), bitem('У'), bitem('Ь'), bitem('Э')],
        [bitem('Ц'), bitem('Л'), bitem('Ъ'), bitem('В'), bitem('Щ'), bitem('С')],
        [bitem('Х'), bitem('А'), bitem('Н'), bitem('Ч'), bitem('К'), bitem('Е')],
        [bitem('Т'), bitem('Ш'), bitem('Ю'), bitem('З'), bitem('И'), bitem('А')],
      ]
    }
  }

  render() {
    return <div className="content is-medium">
      <h2 className="title">Это было что-то секретное?</h2>
      <p>Наверняка кто-то сейчас издал звук "пфф".</p>
      <p>Ты все ближе и ближе к цели. Попробуем теперь в Форт Боярд.</p>
      <p>Это что у нас, конкурс в конкурсе?</p>
      <p>Слова у нас такие: <i>ящик, бумага, столбцы, статья</i></p>
      <p>Целых четыре, все команды в этой игре угадывают главные слова на раз-два с таким набором подсказок.</p>
      <p>Кстати, таблица внизу точь-в-точь та же, что и в Форт Боярд.</p>
      {this.renderGrid()}
      {this.state.showTiger ? <img src="tiger.png" className="tiger" alt="тигр"></img> : null}
      <div className="columns is-mobile is-centered">
        <div className="column is-half has-text-centered">
          <button className="button is-warning" onClick={this.handleSubmit}>Проверить</button>
        </div>
      </div>
    </div>
  }

  classes = (clicked: boolean) => 'column is-1 has-text-centered' + (clicked ? ' clicked' : '')

  renderGrid() {
    return this.state.grid.map((row, i) =>
      <div key={`row-${i}`} className="columns is-mobile is-centered grid">
        {row.map((item, j) =>
          <div key={`cell-${j}`} className={this.classes(item.state)} onClick={() => this.handleClick(i, j)}>{item.letter}</div>
        )}
      </div>
    )
  }

  handleClick = (i: number, j: number) => {
    const grid = this.state.grid.slice()
    grid[i][j].state = !grid[i][j].state
    this.setState({grid})
  }

  handleSubmit = () => {
    const word = this.state.grid.flatMap(
      row => row.map(item => item.state ? item.letter : null)
    ).filter(l => l).sort().join('')
    if (word === 'ААГЕЗТ') {
      this.setState({guessed: true, showTiger: false})
      this.props.roundCompletedCallback()
    } else {
      const grid = this.state.grid.slice()
      grid.forEach(row => row.forEach(item => item.state = false))
      this.setState({grid, guessed: false, showTiger: true})
      setTimeout(() => this.setState({showTiger: false}), 2000)
    }
  }

  static tips(): Tuple[] {
    return []
  }

  static get needForm() {
    return false
  }
}

export default Fort
