import React from 'react'

class Science extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Science, Bitch!</h2>
      <div className="columns is-mobile is-centered is-gapless">
        <div className="column has-text-centered">
          <img src="science.png" alt="Сайнс" width="256px" />
        </div>
      </div>
      <p>Давай познакомимся поближе.</p>
      <p>Пожалуйста, угадай название места, сервера, на котором я нахожусь.</p>
      <p>Это еще, между прочим, "начальник" Гэндальфа.</p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Бесящий уровень?', 'Тут же фэнтези.'],
      ['Наверное стоит сделать исследование', 'Погуглить, например.'],
      ['Так и быть, подсказка', 'Это один из валар.'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Science
