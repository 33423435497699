import React from 'react'

class Introduction extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Привет</h2>
        <p>
          Это квест, который приведет тебя к твоему новогоднему подарку. Будет несколько этапов, в каждом из которых нужно узнать кодовое слово.
        </p>
        <p>
          Сейчас ты читаешь задание к первому этапу, который является обучающим, поэтому он будет очень легким, но таким образом ты сможешь разобраться в интерфейсе.
        </p>
        <p>
          Справа (или снизу, если ты используешь мобильное устройство) есть поле ввода, где нужно ввести кодовое словое, чтобы перейти на следующий уровень.
        </p>
        <p>
          Имей ввиду, что это одностраничный сайт, поэтому кнопка "Назад" в браузере не приведет тебя к предыдущему заданию,
          но не переживай: твой прогресс сохраняется, поэтому если ты откроешь сайт в другой вкладке или обновишь страницу,
          то ничего не будет потеряно.
        </p>
        <p>
          Введи код <code>привет</code>, чтобы продолжить.
        </p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['В чем проблема?', 'Это же самый простой уровень.'],
      [':(', 'Ты точно умеешь читать?']
    ]
  }

  static get needForm() {
    return true
  }
}

export default Introduction
