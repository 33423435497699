import React from 'react'

class Rocket extends React.Component {
  render() {
    return <div className="content is-medium">
    <h2 className="title">Уже попробовали?</h2>
    <p>Если нет, то лучше отложите на дижестив.</p>
    <p>Или вы уже заканчиваете и расходитесь? :3</p>
    <p>Возможно, некоторые из вас заскучали. Поэтому у меня есть вопрос совсем из другой тематики. Про ракеты. Ядерные.</p>
    <p>Сможете мне назвать что-то очень современное, вот-вот разрабатывающееся?</p>
    <p>Подсказка, которая вам не понадобится: тем же именем был назван народ, что жил на рубеже эр на территории Крыма и был известен, своими катафрактами - тяжелая конница, что еще была в игре Rome: Total War.</p>
  </div>
  }

  static tips(): Tuple[] {
    return [
      ['Да нет', 'Другой вариант'],
      ['Ошибка', 'Я что, должен тут какую-то подсказку давать?'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Rocket
