import React from 'react'

class Mass extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Нот бэд</h2>
      <div className="columns is-mobile is-centered is-gapless">
        <div className="column has-text-centered">
          <img src="what.png" alt="Что" width="200px"/>
        </div>
      </div>
      <p>Это было хоть сколько-то сложно?</p>
      <p>Могу только надеяться, что ты потратил какое-то время на предыдущем уровне, и, возможно, немного устал.</p>
      <p>В плане отдыха (кстати в теории игр это называется <strong>снятие дискомфорта проигрыша</strong>), конечно могу предложить задачу такую:</p>
      <p>Какая масса человека на Марсе, если на Земле она <strong>80 кг</strong>, при условии, что на Марсе ускорение свободного падения (g) <strong>в 3 раза меньше</strong>?</p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Омг', 'Физику нужно вспомнить.'],
      ['Опять нет', 'Масса, вес, m, P.'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Mass
