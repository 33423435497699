import React from 'react'

class Blank extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Это было просто</h2>
      <p>
        Чуть посложнее
      </p>
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <p className="has-text-white">
        кубик
      </p>
      <br /><br />
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Ой', 'Даже не знаю как тебе подсказать.'],
      ['', 'Как-то ты же должен найти ответ.'],
      ['Ищи лучше', 'Это недалеко от сюда.'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Blank
