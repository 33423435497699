import React from 'react'
import { Cookies } from 'react-cookie'
import Admin from './Admin'
import * as components from './levels'
import Form from './Form'

type LevelComponent = React.ComponentClass<any, any> & Level

const Levels = new Map<string, LevelComponent>([
  ['introduction', components.Introduction],
  ['qr', components.Qr],
  ['blank', components.Blank],
  ['song', components.Song],
  ['base64', components.Base64],
  ['painting', components.Painting],
  ['cork', components.Cork],
  ['wine', components.Wine],
  ['rocket', components.Rocket],
  ['fort', components.Fort],
  ['flag', components.Flag],
  ['line', components.Line],
  ['quote', components.Quote],
  ['mass', components.Mass],
  ['science', components.Science],
  ['color', components.Color],
  ['english', components.English],
  ['final', components.Final]
])

const CookiesMaxAge = 24 * 60 * 60 // in secs

type QuestState = {
  currentLevelName: string
  level: LevelComponent
}

class Main extends React.Component<{}, QuestState> {
  private readonly cookies: Cookies = new Cookies()
  private readonly levelsOrder = Array.from(Levels.keys())

  constructor(props: {}) {
    super(props)

    const initial = this.levelsOrder[0]
    const currentLevelName: string = this.cookies.get('current') || initial
    const currentLevel = Levels.get(currentLevelName) || Levels.get(initial)!

     this.state = {
      currentLevelName,
      level: currentLevel
    }
  }

  nextLevel = () => {
    const currentIndex = this.levelsOrder.findIndex(l => l === this.state.currentLevelName)
    if (currentIndex >= this.levelsOrder.length - 1) {
      return
    }
    const nextLevelName = this.levelsOrder[currentIndex + 1]
    const nextLevel = Levels.get(nextLevelName)!

    this.setState({currentLevelName: nextLevelName, level: nextLevel})
    this.cookies.set('current', nextLevelName, {maxAge: CookiesMaxAge})
  }

  prevLevel = () => {
    const currentIndex = this.levelsOrder.findIndex(l => l === this.state.currentLevelName)
    if (currentIndex <= 0) {
      return
    }
    const prevLevelName = this.levelsOrder[currentIndex - 1]
    const prevLevel = Levels.get(prevLevelName)!

    this.setState({currentLevelName: prevLevelName, level: prevLevel})
    this.cookies.set('current', prevLevelName, {maxAge: CookiesMaxAge})
  }

  render() {
    return <section className="section">
      { process.env.NODE_ENV === 'development' ? <Admin prev={this.prevLevel} next={this.nextLevel} /> : null }
      <div className="container">
        {this.state.level.needForm ? this.renderWithForm() : this.renderWithoutForm()}
      </div>
    </section>
  }

  private renderWithForm() {
    return <div className="columns">
      <div className="column is-half">
        {this.level}
      </div>
      <div className="column">
          <div className="columns is-mobile is-centered">
              <div className="column is-full-mobile is-two-thirds-tablet">
                <Form level={this.state.level} roundCompletedCallback={this.nextLevel} currentLevel={this.state.currentLevelName}/>
              </div>
          </div>
      </div>
    </div>
  }

  private renderWithoutForm() {
    return <div className="columns is-centered">
      <div className="column is-half">
        {this.level}
      </div>
    </div>
  }

  private get level() {
    if (this.state.level.needForm) {
      return React.createElement(this.state.level, {});
    } else {
      return React.createElement(this.state.level, {
        currentLevel: this.state.currentLevelName,
        roundCompletedCallback: this.nextLevel
      })
    }
  }
}

export default Main
