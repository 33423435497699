import React from 'react'

class Cork extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Вот это да!</h2>
      <p>Прошлое испытание было достаточно серьезным. Даже для тех, кто читал "Ангелы и Демоны".</p>
      <p>Думаю, стоит взять небольшой перерыв и предложить что-то попроще.</p>
      <p>Или нет?</p>
      <p>Открывайте вино, откидывайтесь на спинку стула или, может быть, дивана. Я не уверен, что у вас в бокале сейчас, но что бы вы делали, если бы у вас была бутылка очень старого вина?</p>
      <div className="columns is-mobile is-centered is-gapless">
        <div className="column has-text-centered">
          <img src="wine.jpg" alt="Кадр" width="512px"/>
        </div>
      </div>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Подумай', 'Тут и картинка к месту и текст.'],
      ['Такс', 'Тут про вино, про старое вино.'],
      ['А картинка?', 'С чем она обычно ассоциируется у интернет пользователей?'],
      ['Я все же настаиваю', 'Про кого говорил этот герой в этот момент?'],
      ['Уф', 'Не знаю как еще подсказать. Слово простое, с вином связанное.']
    ]
  }

  static get needForm() {
    return true
  }
}

export default Cork
