import React from 'react'

class Wine extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Кто бы мог подумать</h2>
      <p>Вот так он выглядит</p>
      <div className="columns is-mobile is-centered is-gapless">
        <div className="column has-text-centered">
          <img src="gypsy.png" alt="Кадр" width="256px"/>
        </div>
      </div>
      <p>Эти усики вставляют между бутылкой и пробкой и постепенно выкручивают. Только такой метод позволяет сохранить пробку, которая размокла от вина за десятилетия.</p>
      <p>Бутылки хранятся в горизонтальном положении, поэтому вино соприкасается с пробкой, оттого она и портится.</p>
      <p>Может продолжим эту тему?</p>
      <p>В этот раз по-настоящему будет полегче. Без двойных шагов и намеков.</p>
      <p>Я просто хочу, чтобы ты вспомнил вино, к созданию которого приложил руку предок The Beatles, добавив в вино бренди, чтобы оно не испортилось,
        пока он везет его на родину.
      </p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Нет', 'И тут не серьезных подсказок'],
      ['Нет', 'Откуда The Beatles?'],
      ['Нет', 'Это вино как дижестив использут. Не зря же бокалы такие появились у тебя в доме?'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Wine
