import React from 'react'

class Song extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Я тут подумал...</h2>
      <p>Что, если мы с тобой поиграем чуть подольше?</p>
      <p>В конце концов что еще делать в новогоднюю ночь, кроме как работать мозгами, попивая вино или просекко какое? Говорят, что как встретишь Новый год,
        так его и проведешь. У нас у всех есть шанс стать немного умнее в <strong>2020</strong>.</p>
      <p>Поэтому смело пользуйся помощью своих старых друзей.</p>
      <p><i>I know what I need I need you<br />I can't see nothin else all I can see is you</i></p>
      <p>Кстати, касательно сложности заданий. Не думаю, что будет все по нарастающей, скорее волнообразно, чтобы были уровни, где ты смог отдохнуть (если что, это гендернонейтрально).</p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Подумай', 'В тексте есть подсказки.'],
      ['Вообще-то', 'Это не самый сложный уровень.'],
      ['Нет', 'Я не могу больше тебе подсказывать.'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Song
