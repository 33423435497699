import React from 'react'

class Line extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Давай подытожим</h2>
        <p>
          Какие у нас были задания?
        </p>
        <ul>
          <li>Несколько уровней для айтишников каких</li>
          <li>Для любителей вечером алкоголю в квартире попить</li>
          <li>Парочку для гумунитариев недобитых</li>
          <li>Немного по мелочи: путешественники, ракетчики и другие представители вчерашнего пролетариата</li>
        </ul>
        <p>Что еще можно зацепить?</p>
        <p>Может теорию какую, некогда популярную?</p>
        <blockquote className="has-background-dark has-text-grey-lighter">Маркс - 1, Энгельс - 2, * - 1</blockquote>
        <p>Вот это задачку я задал.</p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Увы', 'Это не вот уж сложно'],
      ['Гм', 'Что может звездочка означать?'],
      ['Кстати', '1+2+1 = 4'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Line
