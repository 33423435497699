import React from 'react'

class Quote extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Хорошо, поехали дальше</h2>
      <p>Тот факт, что я что-то подытожил на прошлом этапе ничего не значит.</p>
      <p>Раз уж мы начали просветительную деятельность, давай продолжим с такими вопросами. Они не только интересные, как я надеюсь,
        но еще и позволяют узнать что-то новое.
      </p>
      <p>Это неплохо, ведь, как известно, <i>разруха не в клозетах, а в головах</i></p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Это не серьезно', 'Уже с десяток уровней пройдено, а еще гуглить не научились.'],
      ['Не просто же так', 'Курсив существует.'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Quote
