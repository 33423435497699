import React from 'react'

class Painting extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Надеюсь вы не спрашивали о помощи?</h2>
        <p>
          Достачно технического. Можно выдыхать.
        </p>
        <p>
          Проверим насколько вы гумунитарий. Я полагаю на 100%, поэтому это будет не так-то сложно.
        </p>
        <div className="columns is-mobile is-centered is-gapless">
          <div className="column has-text-centered">
            <img src="painting.png" alt="Картинка" width="256px" />
          </div>
        </div>
        <p>
          Кстати, даю вам возможность почувствовать себя Робертом Лэнгдоном и сделать ровно такое же открытие.
        </p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['А вот и нет', 'Я бы не считал этот уровень одним из самых сложных, если бы все было так просто.'],
      ['Опять нет', 'Думай лучше, пытайся разное.'],
      ['И еще раз нет', 'Подсказка про Роберта Лэнгдона очень к месту.'],
      ['Хм', 'Леонардо Ди Каприо, Микеланджело Буонарроти.'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Painting
