import React from 'react'
import Notification from './Notification'
import tupleGenerator from '../utils/tuple-generator'

type ServerResponse = {
  success: boolean
  error: string
  payload: {
    [key: string]: string
  }
}

enum SubmissionState {
  Success,
  Wrong,
  Fatal
}

type FormProps = {
  currentLevel: string
  level: Level,
  roundCompletedCallback: () => void
}

type FormState = {
  code: string,
  submissionState: SubmissionState,
  message: Tuple
}

const defaultMessages: Tuple[] = [
  ['Увы', 'Попробуй еще раз'],
  ['Сожалею', 'Но тебе придется подумать еще'],
  ['Нет', 'Плохая попытка'],
  ['Еще раз нет', 'Такая же плохая'],
  ['Ээ', 'Не верно'],
  ['Ничего страшного', 'Но попробуй еще'],
  ['Не обижайся', 'Так себе вариант']
]

class Form extends React.Component<FormProps, FormState> {
  notifications: Generator<Tuple>

  constructor(props: FormProps) {
    super(props)

    this.state = {
      code: '',
      submissionState: SubmissionState.Success,
      message: ['', '']
    }

    this.notifications = tupleGenerator(this.props.level.tips(), defaultMessages)
  }

  componentDidUpdate(prevProps: FormProps) {
    if (prevProps.currentLevel !== this.props.currentLevel) {
      this.notifications = tupleGenerator(this.props.level.tips(), defaultMessages)
    }
  }

  render() {
    return <form onSubmit={this.handleSubmit}>
      <label className="label">Введите код для доступа к следующему уровню</label>
      <div className="field has-addons">
        <div className="control is-expanded">
          <input className="input" placeholder="Код" value={this.state.code} onChange={this.handleChange}/>
        </div>
        <div className="control">
          <button type="submit" className="button is-primary" disabled={!this.state.code}>Отправить</button>
        </div>
      </div>
      {
        this.state.submissionState !== SubmissionState.Success ?
        <Notification isFatal={this.state.submissionState === SubmissionState.Fatal} title={this.state.message[0]} message={this.state.message[1]} /> :
        null
      }
    </form>
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({code: event.currentTarget.value.toLowerCase()})
  }

  private handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const payload = {
      level: this.props.currentLevel,
      code: this.state.code
    }

    const response = await fetch('/api/validate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    if (response.status !== 200 && response.status !== 403) {
      this.setState({
        code: '',
        submissionState: SubmissionState.Fatal,
        message: ['Сайт сломался', 'Скорее всего, ты получишь подарок просто так :(']
      })
      return
    }

    const body = await response.json() as ServerResponse

    if (body.success) {
      this.setState({code: '', submissionState: SubmissionState.Success})
      this.props.roundCompletedCallback()
    } else {
      const nextMessageTuple: Tuple = this.notifications!.next().value
      this.setState({
        submissionState: SubmissionState.Wrong,
        message: nextMessageTuple
      })
    }
  }
}

export default Form
