import React from 'react'

class Base64 extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Ого</h2>
      <p>Уже какой-то интерактив начался.</p>
      <p>У меня руки чешутся (на самом деле у меня нет рук, так как я обычный одностраничный сайт, но так принято говорить) предложить что-то связанное с шифрованием, или где нужно смотреть код страницы, открывать картинки в текстовых редакторах,
        но боюсь, что это не для менеджеров среднего звена или продающих копирайтеров каких.
      </p>
      <p>Или вы не такие?</p>
      <p>Может проверим?</p>
      <p>Как насчет <code>0YLRgNC10YPQs9C+0LvRjNC90LjQug==</code>?</p>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Ну да', 'Это все таки шифрование какое-никакое.'],
      ['Нет', 'Обрати внимание на == в конце.'],
      ['Не-а', 'Вспомни, может быть Артемия Лебедева, его проекты.'],
      ['Опять нет', ''],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Base64
