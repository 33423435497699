import React from 'react'

type NotificationProps = {
  isFatal: boolean
  title: string
  message: string
}

const Notification: React.FC<NotificationProps> = ({isFatal, title, message}) =>
  <section className={'hero ' + (isFatal ? ' is-danger' : 'is-warning')}>
    <div className="hero-body">
      <div className="container">
        <h2 className="title">
          {title}
        </h2>
        <h3 className="subtitle">
          {message}
        </h3>
      </div>
    </div>
  </section>

export default Notification
