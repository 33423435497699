import React from 'react'

class Flag extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Сложно?</h2>
      <p>Предыдущий уровень оказался необычным. Просто нужна была практика программирования с этой библиотекой и мне нужен был небольшой интерактив.</p>
      <p>Гоу переключимся на новую тему, потрогаем другие сферы знаний. Какие у нас еще не затронуты?</p>
      <p>Может путешествия?</p>
      <div className="columns is-mobile is-centered is-gapless">
        <div className="column has-text-centered">
          <img src="flag.png" alt="Флаг" width="320px"/>
        </div>
      </div>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Близко', 'Но ведь все не так просто, как кажется, да?'],
      ['Не-а', 'Метаморфозы какие мей би.']
    ]
  }

  static get needForm() {
    return true
  }
}

export default Flag
