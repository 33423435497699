import React from 'react'

class English extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Almost Done</h2>
      <p>As I mentioned a while ago, having an easy round as the last step is a must so players can feel better afterward.</p>
      <p>This is the case.</p>
      <p>This is the case and that's why we had both challenging and effortless levels. This is the latter.</p>
      <p>By the way, I'm intentionally using rarer words to urge you to use a dictionary or some online service or better, to make you feel terrible that you are having a hard time translating this.</p>
      <p>Nonetheless, which level was the most complicated for you?</p>
    </div>
  }

  static tips(): Tuple[] {
    return []
  }

  static get needForm() {
    return true
  }
}

export default English
