export default function* (options: Tuple[], defaults?: Tuple[]): Generator<Tuple> {
  for (const tuple of options) {
    yield tuple
  }
  if (!defaults) {
    return
  }

  while (true) {
    for (const tuple of defaults) {
      yield tuple
    }
  }
}
