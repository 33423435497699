import React from 'react'

class Color extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Может еще немного по дизайну пробежимся?</h2>
      <div className="columns is-mobile is-centered is-gapless">
        <div className="colored-square">
        </div>
      </div>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Неправильно', 'Что это за квадрат?'],
      ['Опять нет', 'Это же про дизайн'],
      ['Вроде как', 'Наполен связан как-то с ответом, штаны ли его.'],
    ]
  }

  static get needForm() {
    return true
  }
}

export default Color
