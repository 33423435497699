import React from 'react'

type FinalProps = {
  currentLevel: string
  roundCompletedCallback: () => void
}

type FinalState = {
  reward: string
}

class Final extends React.Component<FinalProps, FinalState> {
  constructor(props: FinalProps) {
    super(props)

    this.state = {
      reward: ''
    }
  }

  componentDidMount() {
    fetch('/api/reward?token=itsmeyouknow')
      .then(r => r.json())
      .then(data => this.setState({reward: data['reward']}))
  }

  render() {
    return <div className="content is-medium">
      <h2>Поздравляю!</h2>
        <p>
          Вы прошли все уровни. C Новым Годом!
        </p>
        <p>Как последний уровень? Доставил дискомфорт?</p>
        <p>Вот и подарок, с помощью которого, к следующему году, я надеюсь, этот дискомфорт исчезнет:</p>
        <div className="columns is-mobile is-centered is-gapless">
        <div className="column has-text-centered">
          <img src="tree.png" alt="Елка" width="300px"/>
          </div>
        </div>
        <p className="has-text-centered">
        <a href="https://apps.apple.com/us/app/skyeng/id1065290732?mt=8" className="apple-badge"></a>
        </p>
        <blockquote className="reward">
          {this.state.reward}
        </blockquote>
        <p>Кстати, подарок под ёлкой, как и полагается.</p>
      </div>
  }

  static tips(): Tuple[] {
    return []
  }

  static get needForm() {
    return false
  }
}

export default Final
