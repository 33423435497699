import React from 'react'

class Qr extends React.Component {
  render() {
    return <div className="content is-medium">
      <h2 className="title">Итак</h2>
        <p>
          Первый обучающий раунд пройден, маленький шажочек сделан, поздравляю.
        </p>
        <p>
          Теперь чуть-чуть сложнее, но на самом деле нет.
        </p>
        <p>
          Мне просто нужен еще один простой раунд для разработки и проверки как все работает вместе с картинками.
        </p>
        <p>
          Встретимся через несколько секунд.
        </p>
        <p>
          Кстати, возможно тебе придется просить помощи у Андроида.
        </p>
        <div className="columns is-mobile is-centered is-gapless">
          <div className="column has-text-centered">
            <img src="qr.svg" alt="Обычный кюер" width="128px" height="128px"/>
          </div>
        </div>
    </div>
  }

  static tips(): Tuple[] {
    return [
      ['Что нет так?', 'Что-то с кодировкой?'],
      ['Так', 'Просто наведи телефоном на QR.']
    ]
  }

  static get needForm() {
    return true
  }
}

export default Qr
